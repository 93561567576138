import React from "react";

import Chatbot from "./Chatbot"; // Importing the Chatbot component
import styles from "./chatbot.module.css"; // Importing styles
import { chatdaniels } from "../../../constants/images"; // Importing image

const ChatBotDanielsIndex = ({ chatId2, isIconsetVisible, setIconsetVisible, userImage, connectToSocket, socketNewMessage, isChatClose, clearSocketState, template, apiBaseUrl ,chatBotAdminData }) => {

  // Function to toggle the visibility of the chat icon
  const toggleIconset = () => {

    // Toggle the visibility of the chat icon
    setIconsetVisible((prev) => !prev);
    // Clear socket state
    clearSocketState();
  };

  return (
    <div style={{ zIndex: "999998", position: "fixed" }} >
      <div className={styles.full_chatbot}>
        {/* Render the Chatbot component if the chat icon is visible */}
        {isIconsetVisible && (
          <Chatbot
            chatId2={chatId2}
            userImage={userImage}
            onClose={toggleIconset}
            connectToSocket={connectToSocket}
            socketNewMessage={socketNewMessage}
            isChatClose={isChatClose}
            template={template}
            apiBaseUrl={apiBaseUrl}
            chatBotAdminData={chatBotAdminData}
          />
        )}
        <div className={styles.iconset} >
          {/* Render the chat icon if it is not visible */}
          {!isIconsetVisible && (
            <div className={styles.chat_initiate}>
              <div className={styles.chatbot_outer} onClick={toggleIconset}>
                {/* Chat icon */}
                <img src={chatdaniels}></img>
                <span>Chat with us , we’re online</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBotDanielsIndex;
