import React, { useEffect, useState } from 'react';
import { FormLabel, } from '@mui/material';
import styles from './InputStyles.module.css';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const MultipleCheckbox = ({ options, name, setCaptureFieldValue, template }) => {

    const [selectedOptions, setSelectedOptions] = useState([]);

    const toggleOption = (option) => {
        setSelectedOptions((prev) => prev.includes(option) ? prev.filter((opt) => opt !== option) : [...prev, option]);
    };

    const getHoverButtonColor = (template) => {
        const colors = {
            btc: "#3c6e95",
            radcliffe: "#143557",
            talbies: "#B09981",
            charlessinclair: "#c7b354",
            denhan: "#b82142",
            daniels: "#ffffff",
            magicbrick: "#545659",
            propertyhubltd: "#545659",
            lundiniom: "#545659",
            eliteintl: "#F80229",
            foster: "#403634",
        };
        return colors[template] || "#933eff";
    };

    useEffect(() => {
        const selectedOptionsString = selectedOptions?.map(option => option.charAt(0).toUpperCase() + option.slice(1).replaceAll("_", " ")).join(", ");
        setCaptureFieldValue(selectedOptionsString);
    }, [selectedOptions])

    return (
        <div className={`${styles.inputContainer} ${styles[`input_${template}`]}`}>
            <FormLabel className={styles.inputLabel}>{name}</FormLabel>
            <span className={styles.subText + " " + styles["sub_" + template]}>Choose one or more options</span>
            <Stack
                direction="row"
                sx={{ flexWrap: "wrap" }}
                spacing={2}
                className={styles.sender_right_side}
            >
                {options?.map((data, index) => (
                    <span key={index} style={{ margin: "3px 2px" }}>
                        <Chip
                            label={data.label}
                            variant={selectedOptions.includes(data.value) ? "filled" : "outlined"}
                            onClick={() => toggleOption(data.value)}
                            style={{
                                backgroundColor: selectedOptions.includes(data.value) ? getHoverButtonColor(template) : "",
                                borderColor: getHoverButtonColor(template),
                                color: selectedOptions.includes(data.value)
                                    ? ["daniels"].includes(template) ? "#343434" : "white"
                                    : ["daniels"].includes(template) ? "#edebeb" : "#33475b",
                            }}
                            className={styles.pill_check}
                        />
                    </span>
                ))}
            </Stack>
        </div>
    );
};

export default MultipleCheckbox;
