import React, { useState } from 'react';
import { FormLabel, } from '@mui/material';
import styles from './InputStyles.module.css';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const SingleRadio = ({ options, name, setCaptureFieldValue, template }) => {
    const [selected, setSelected] = useState('');

    const getHoverButtonColor = (template) => {
        const colors = {
            btc: "#3c6e95",
            radcliffe: "#143557",
            talbies: "#B09981",
            charlessinclair: "#c7b354",
            denhan: "#b82142",
            daniels: "#ffffff",
            magicbrick: "#545659",
            propertyhubltd: "#545659",
            lundiniom: "#545659",
            eliteintl: "#F80229",
            foster: "#403634",
        };
        return colors[template] || "#933eff";
    };

    return (

        <div className={`${styles.inputContainer} ${styles[`input_${template}`]}`}>
            <FormLabel className={styles.inputLabel}>{name}</FormLabel>
            <span className={styles.subText + " " + styles["sub_" + template]}>Choose one of the following options.</span>
            <Stack
                direction="row"
                sx={{ flexWrap: "wrap" }}
                spacing={2}
                className={styles.sender_right_side}
            >
                {options?.map((data, index) => (
                    <span key={index} style={{ margin: "3px 2px" }}>
                        <Chip
                            label={data.label}
                            variant={selected === data.value ? "filled" : "outlined"}
                            onClick={() => {
                                setSelected(data.value)
                                setCaptureFieldValue(data.label.charAt(0).toUpperCase() + data.label.slice(1).replaceAll("_", " "))
                            }}
                            style={{
                                backgroundColor: selected === data.value ? getHoverButtonColor(template) : "",
                                borderColor: getHoverButtonColor(template),
                                color: selected === data.value
                                    ? ["daniels"].includes(template) ? "#343434" : "white"
                                    : ["daniels"].includes(template) ? "#edebeb" : "#33475b",

                            }}
                            className={styles.pill_check}
                        />
                    </span>
                ))}
            </Stack>
        </div>
    );
};

export default SingleRadio;
