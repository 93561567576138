//crm
const API_HOST = process.env.REACT_APP_API_HOST;
const API_BASE_URL = `${API_HOST}/api/v1/crm`;
// devcrm
const DEV_API_HOST = process.env.REACT_APP_DEV_API_HOST;
const DEV_API_BASE_URL = `${DEV_API_HOST}/api/v1/crm`;
// devtest
const DEVTEST_API_HOST = process.env.REACT_APP_DEVTEST_API_HOST;
const DEVTEST_API_BASE_URL = `${DEVTEST_API_HOST}/api/v1/crm`;
//pjdevcrm
const PJDEV_API_HOST = process.env.REACT_APP_PJDEV_API_HOST;
const PJDEV_API_BASE_URL = `${PJDEV_API_HOST}/api/v1/crm`;

export const getMainApiHost = (app_host) => {
    if (app_host === 'dev') {
        return [DEV_API_HOST, DEV_API_BASE_URL]
    }
    else if (app_host === 'pjdev') {
        return [PJDEV_API_HOST, PJDEV_API_BASE_URL]
    }
    else if (app_host === 'devtest') {
        return [DEVTEST_API_HOST, DEVTEST_API_BASE_URL]
    }
    else {
        return [API_HOST, API_BASE_URL]
    }
} 