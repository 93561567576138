import { useState } from "react";
import Axios from "../../utils/axios";

const useBotData = (apiBaseUrl) => {
    const [waitingTime, setWaitingTime] = useState(0);
    const [isChatbotActive, setIsChatbotActive] = useState(false);
    const [chatbotFullData, setChatbotFullData] = useState(false);

    const getChatbotData = (key) => {
        if (checkBotDataInStorage()) { // Check if bot data is stored in local storage if yes then dont make validateKey API call
            setChatbotFullData(getBotDataFromStorage());
            setIsChatbotActive(true);
            return;
        } else {
            Axios.post(`${apiBaseUrl}/domainKey/validateKey`, { encryptedKey: key }).then((res) => {
                setWaitingTime(res.data?.data?.chatBotData?.waitingTime)
                setChatbotFullData(res.data?.data);
                setBotDataToStorage(res.data?.data);
                setIsChatbotActive(true);
            }).catch((error) => {
                setWaitingTime(0);
                setIsChatbotActive(false)
            });
        }
    }

    const getBotDataFromStorage = () => {
        const botData = localStorage.getItem("jinniBotData");
        if (botData) {
            return JSON.parse(botData);
        }
        return null;
    }
    
    const setBotDataToStorage = (botData) => {
        localStorage.setItem("jinniBotData", JSON.stringify(botData));
    }
    
    const removeBotDataFromStorage = () => {
        localStorage.removeItem("jinniBotData");
    }
    
    const checkBotDataInStorage = () => {
        const botData = getBotDataFromStorage();
        if (botData) {
            return true;
        }
        return false;
    }
    

    return {
        getChatbotData, 
        chatbotFullData,
        waitingTime, 
        isChatbotActive,
        getBotDataFromStorage,
        setBotDataToStorage,
        removeBotDataFromStorage,
        checkBotDataInStorage,
    }
}

export default useBotData;