import React from 'react';
import styles from './PropertyUnavailable.module.css';
import { NoData } from '../../../constants/images';
import Chip from "@mui/material/Chip";

const PropertyUnavailable = ({ template,onClick,isActive,status=false }) => {

  return (
    <div className={`${styles.container} ${styles[`container_${template}`]}`}>
      <img
        src={NoData} // Replace with the actual path to your house icon
        alt="House Icon"
        className={styles.icon}
      />
          <h2 className={styles.title} style={{marginBottom:status?'15px':'0px'}}>{status? 'No property found for this postcode.':'Property Data Unavailable'}</h2>
      {!status &&
      <p className={styles.description}>
        There is no data currently available for this property.
      </p>
      }
      {/* <button className={styles.button}>Try Another Postcode</button> */}

      {isActive && (
        <>
          <Chip
            label="Try Another Postcode"
            clickable
            className={styles.chip}
            onClick={() => onClick("Try Another Postcode")}
          />
          <Chip
            label="Continue Chat"
            clickable
            className={styles.chip}
            style={{ marginLeft: "5px" }}
            onClick={() => onClick("Continue Chat")}
          />
        </>
      )}
    </div>
  );
};

export default PropertyUnavailable;
