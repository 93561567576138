import React, { useEffect, useRef, useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import styles from "./chatbot.module.css";
import BotCal from "../../customComponents/BotCal.jsx";
import { sendIcon } from "../../../constants/images.js";
import RangeSelector from '../../customComponents/inputFields/RangeSelector.jsx';
import { formatBotResponse } from "../../../utils/validators.js";

import SelectProperty from "../../customComponents/valuation/SelectProperty.jsx";
import PropertyValuation from "../../customComponents/valuation/PropertyValuation.jsx";
import PropertyUnavailable from "../../customComponents/valuation/PropertyUnavailable.jsx";

const ChatbotMessages = ({ chatId, messages, chatLoading, handleSendDailogApi, createChat, onChatMsgSubmit,
  createType, chatBotAdminData, stopSendloading, chatClose, onClose, handleRating, apiBaseUrl, range, maxRange, rangeData, setRangeData }) => {

  // Check if form is already submitted
  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId));
  const chatContainerRef = useRef(null);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    });

    if (chatContainerRef.current) {
      observer.observe(chatContainerRef.current, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={styles.all_chatpart_body} ref={chatContainerRef}>
      {/* Map through messages and render them */}
      {messages?.map((message, index) => {
        const isUserMessage = (message?.sender === isFormAlreadySubmitted?._id || message?.sender == null) && (!message?.isBot);
        return (
          <>
            {/* Render sender information */}
            <div
              className={isUserMessage || message?.sender === "you" ? `${styles.sender_box} ${styles.sender_right_side}` : styles.sender_box
              }>
              {isUserMessage || message?.sender === "you" ? (
                <>  You  <span>  {isFormAlreadySubmitted?.name?.slice(0, 1) || "y"}  </span></>
              ) : (
                <>
                  <img src={(message?.isBot ? chatBotAdminData?.chatBotData?.chatbotImage : message?.sender?.avatar || chatBotAdminData?.chatBotData?.chatbotImage) || sendIcon} alt="" />
                  {(message?.isBot ? chatBotAdminData?.chatBotData?.chatbotName : message?.sender?.name || chatBotAdminData?.chatBotData?.chatbotName) || "Bot"}
                </>
              )}
            </div>
            {message?.propertyList?.data?.raw_data?.length > 0 && (
              <div>
                <SelectProperty
                  template={""}
                  properties={message?.propertyList?.data?.raw_data}
                  isActive={index === messages?.length - 1}
                  handleSendDailogApi={handleSendDailogApi}
                  chatId={chatId}
                />
              </div>
            )}
            {
              message?.propertyDetails?.status === "success" && !(message?.propertyDetails?.saleValuation?.status || message?.propertyDetails?.letValuation?.status) && <div>
                <PropertyValuation template={""} propertyDetail={message?.propertyDetails} />
              </div>
            }
           {
              (message?.propertyList?.status === "failed" || message?.propertyDetails?.status === "failed" || (message?.propertyDetails?.valuationType === "both" && message?.propertyDetails?.saleValuation?.status === "failed" && message?.propertyDetails?.letValuation?.status === "failed")) && <div>
                <PropertyUnavailable template={""} propertyDetail={message?.propertyDetails} onClick={(msg) => {
                      if (chatClose) {
                        handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId);
                      } else {
                        if (!createChat) {
                          handleSendDailogApi(msg, chatId);
                        } 
                      }
                    }}
                    isActive={index === (messages?.length - 1)}
                    status={message?.propertyList?.status === "failed"?true:false}
                    />
              </div>
            }
            {
              message?.propertyDetails?.saleValuation?.status === "success" && <div>
                <PropertyValuation template={""} propertyDetail={message?.propertyDetails?.saleValuation} />
              </div>
            }
            {
              message?.propertyDetails?.letValuation?.status === "success" && <div>
                <PropertyValuation template={""} propertyDetail={message?.propertyDetails?.letValuation} />
              </div>
            }
            {/* Render message box */}
            {!message?.propertyList?.status && message?.propertyDetails?.status !== "failed" && <div key={message?._id} className={isUserMessage || message?.sender === "you" ? `${styles.box} ${styles.right_side}` : styles.box}>
              <div dangerouslySetInnerHTML={{ __html: message?.message ? formatBotResponse(message?.message?.replace(/\n/g, " <br/> ")) : "", }}></div>
            </div>}
            {/* Render options as chips if message field is "dailog_select" */}
            {message?.field === "dailog_select" && message?.value !== "" && (
              <Stack
                direction="row "
                sx={{ flexWrap: "wrap" }}
                spacing={2}
                className={styles.sender_right_side}>
                {message.options?.map((data, index) => {
                  return (
                    <span style={{ margin: "3px 2px" }} className={message.value === data && styles.slectedChip}>
                      <Chip label={data} variant={message.value === data ? "filled" : "outlined"} />
                    </span>
                  );
                })}
              </Stack>
            )}
          </>
        );
      })}
      {/* Render options as chips if last message field is "dailog_select" */}
      {messages?.[messages.length - 1]?.field === "dailog_select" &&
        messages?.[messages.length - 1]?.value === "" && (
          <Stack
            direction="row "
            sx={{ flexWrap: "wrap" }}
            spacing={2}
            className={styles.sender_right_side}>
            {messages?.[messages.length - 1].options?.map((data, index) => {
              return (
                <span style={{ margin: "3px 2px" }}>
                  <Chip
                    label={data}
                    variant={messages?.[messages.length - 1].value === data ? "" : "outlined"}
                    onClick={(e) => {
                      if (chatClose) {
                        handleRating(data, setMsg, onClose, isFormAlreadySubmitted, chatId);
                      } else {
                        if (!createChat) {
                          handleSendDailogApi(data, chatId);
                        } else { onChatMsgSubmit(e, data, chatId, isFormAlreadySubmitted, setMsg); }
                      }
                    }}
                  />
                </span>
              );
            })}
          </Stack>
        )}

      {/* Render calendar component if createType is "arrange-callback", "property-viewing", or "schedule-valuation" */}
      {createType && (
        <div className={styles.sender_right_side} style={{ display: "flex" }}>
          <BotCal
            handleSendDailogApi={handleSendDailogApi}
            chatId={chatId}
            chatBotAdminData={chatBotAdminData}
            stopSendloading={stopSendloading}
            messages={messages}
            template=''
            apiBaseUrl={apiBaseUrl}
          />
        </div>
      )}

      {range && <RangeSelector maxRange={maxRange} rangeData={rangeData} setRangeData={setRangeData} />}

      {/* Render typing indicator if chat is loading */}
      {chatLoading && (
        <div className={styles.chat_bubble}>
          <div className={styles.typing}>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotMessages;
