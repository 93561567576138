import React, { useEffect, useRef, useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// Importing styles and custom components
import styles from "./chatbot.module.css";
import BotCal from "../../customComponents/BotCal.jsx";
import { sendimgCharles } from "../../../constants/images.js";
import RangeSelector from '../../customComponents/inputFields/RangeSelector.jsx';
import SingleRadio from "../../customComponents/inputFields/RadioInput.jsx";
import MultipleCheckbox from "../../customComponents/inputFields/MulticheckInput.jsx";
import DropdownMinMax from "../../customComponents/inputFields/DropDownMinMax.jsx";
import DropdownSingle from "../../customComponents/inputFields/DropDown.jsx";
import TextMinMax from "../../customComponents/inputFields/TextMinMax.jsx";

import ResendButton from "../../customComponents/buttons/ResendButtons.jsx";
import PropertyValuation from "../../customComponents/valuation/PropertyValuation.jsx";
import PropertyUnavailable from "../../customComponents/valuation/PropertyUnavailable.jsx";
import SelectProperty from "../../customComponents/valuation/SelectProperty.jsx";


const ChatbotMessages = ({ chatId, messages, chatLoading, handleSendDailogApi, createChat, onChatMsgSubmit, setCaptureFieldError, setCaptureFieldValue,
  createType, chatBotAdminData, stopSendloading, chatClose, onClose, handleRating, template, apiBaseUrl, range, maxRange, rangeData, setRangeData, }) => {

  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId));
  const chatContainerRef = useRef(null);
  const [msg, setMsg] = useState("");

  // Scroll to the bottom of the chat container when new messages are added
  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    });

    if (chatContainerRef.current) {
      observer.observe(chatContainerRef.current, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={styles.all_chatpart_body} ref={chatContainerRef}>
      {/* Render each chat message */}
      {messages?.map((message, index) => {
        const isUserMessage = (message?.sender === isFormAlreadySubmitted?._id || message?.sender == null) && (!message?.isBot);
        return (
          <>
            <div className={isUserMessage || message?.sender === "you" ? `${styles.sender_box} ${styles.sender_right_side}` : styles.sender_box}>
              {/* Display user or bot name */}
              {isUserMessage || message?.sender === "you" ? (
                <>
                  You
                  <span>
                    {isFormAlreadySubmitted?.name?.slice(0, 1) || "y"}
                  </span>
                </>
              ) : (
                <>
                  <img src={(message?.isBot ? chatBotAdminData?.chatBotData?.chatbotImage : message?.sender?.avatar || chatBotAdminData?.chatBotData?.chatbotImage) || sendimgCharles} alt="" />
                  {(message?.isBot ? chatBotAdminData?.chatBotData?.chatbotName : message?.sender?.name || chatBotAdminData?.chatBotData?.chatbotName) || "Bot"}
                </>
              )}
            </div>
            {/* Display message */}
            {(!(message?.propertyDetails?.status) && !message?.propertyList?.status) && <div
              key={message?._id}
              className={
                isUserMessage || message?.sender === "you" ? `${styles.box} ${styles.right_side}` : styles.box
              }>
              <div dangerouslySetInnerHTML={{ __html: message?.message ? message?.message?.replace(/\n/g, " <br/> ") : "", }}></div>
            </div>}
            {/* Render options if message has options */}
            {message?.field === "dailog_select" && message?.value !== "" && (
              <Stack
                direction="row "
                sx={{ flexWrap: "wrap" }}
                spacing={2}
                className={styles.sender_right_side}>
                {message.options?.map((data, index) => {
                  return (
                    <span
                      style={{ margin: "3px 2px" }}
                      className={message.value === data ? styles.slectedChip : styles.sender_chip}>
                      <Chip label={data} variant={message.value === data ? "filled" : "outlined"}
                      />
                    </span>
                  );
                })}
              </Stack>
            )}

          {message?.propertyList?.data?.raw_data?.length > 0 && (
              <div>
                <SelectProperty
                  template={""}
                  properties={message?.propertyList?.data?.raw_data}
                  isActive={index === messages?.length - 1}
                  handleSendDailogApi={handleSendDailogApi}
                  chatId={chatId}
                />
              </div>
            )}
            {
              message?.propertyDetails?.status === "success" && !(message?.propertyDetails?.saleValuation?.status || message?.propertyDetails?.letValuation?.status) && <div>
                <PropertyValuation template={template} propertyDetail={message?.propertyDetails} />
              </div>
            }
             {
              (message?.propertyList?.status === "failed" || message?.propertyDetails?.status === "failed" || (message?.propertyDetails?.valuationType === "both" && message?.propertyDetails?.saleValuation?.status === "failed" && message?.propertyDetails?.letValuation?.status === "failed")) && <div>
                <PropertyUnavailable template={""} propertyDetail={message?.propertyDetails} onClick={(msg) => {
                      if (chatClose) {
                        handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId);
                      } else {
                        if (!createChat) {
                          handleSendDailogApi(msg, chatId);
                        } 
                      }
                    }}
                    isActive={index === (messages?.length - 1)}
                    status={message?.propertyList?.status === "failed"?true:false}
                    />
              </div>
            }
            {
              message?.propertyDetails?.saleValuation?.status === "success" && <div>
                <PropertyValuation template={template} propertyDetail={message?.propertyDetails?.saleValuation} />
              </div>
            }
            {
              message?.propertyDetails?.letValuation?.status === "success" && <div>
                <PropertyValuation template={template} propertyDetail={message?.propertyDetails?.letValuation} />
              </div>
            }
          </>
        );
      })}


      {messages?.[messages.length - 1]?.customFieldTitle?.fieldType === 'text-min-max' && <div key={`${styles.box}`}>
        <div>
          <TextMinMax
            name={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.chatbotLabel}
            prefix={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.prefix}
            postfix={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.postfix}
            setCaptureFieldError={setCaptureFieldError}
            setCaptureFieldValue={setCaptureFieldValue}
            template={template}

          />
        </div>
      </div>}
      {messages?.[messages.length - 1]?.customFieldTitle?.fieldType === 'single-radio' && <div key={`${styles.box}`}>
        <div>
          <SingleRadio name={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.chatbotLabel}
            options={messages?.[messages.length - 1]?.customFieldTitle?.fieldOptions}
            setCaptureFieldError={setCaptureFieldError}
            setCaptureFieldValue={setCaptureFieldValue}
            prefix={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.prefix}
            postfix={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.postfix}
            template={template}
          />
        </div>
      </div>}
      {messages?.[messages.length - 1]?.customFieldTitle?.fieldType === 'multiple-checkbox' && <div key={`${styles.box}`}>
        <div>
          <MultipleCheckbox name={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.chatbotLabel}
            options={messages?.[messages.length - 1]?.customFieldTitle?.fieldOptions}
            setCaptureFieldError={setCaptureFieldError}
            setCaptureFieldValue={setCaptureFieldValue}
            prefix={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.prefix}
            postfix={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.postfix}
            template={template}
          />
        </div>
      </div>}
      {messages?.[messages.length - 1]?.customFieldTitle?.fieldType === 'dropdown-single' && <div key={`${styles.box}`}>
        <div>
          <DropdownSingle name={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.chatbotLabel} options={messages?.[messages.length - 1]?.customFieldTitle?.fieldOptions}
            setCaptureFieldError={setCaptureFieldError}
            setCaptureFieldValue={setCaptureFieldValue}
            prefix={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.prefix}
            postfix={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.postfix}
            template={template}
          />
        </div>
      </div>}
      {messages?.[messages.length - 1]?.customFieldTitle?.fieldType === 'dropdown-min-max' && <div key={`${styles.box}`}>
        <div>
          <DropdownMinMax setCaptureFieldError={setCaptureFieldError}
            setCaptureFieldValue={setCaptureFieldValue}
            prefix={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.prefix}
            template={template}
            postfix={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.postfix}
            name={messages?.[messages.length - 1]?.customFieldTitle?.displayField?.chatbotLabel}
            minOptions={messages?.[messages.length - 1]?.customFieldTitle?.fieldOptions?.filter((data) => data?.optionType?.includes('min'))}
            maxOptions={messages?.[messages.length - 1]?.customFieldTitle?.fieldOptions?.filter((data) => data?.optionType?.includes('min'))} />
        </div>
      </div>}

      {messages?.[messages.length - 1]?.emailVerification && <div key={`${styles.box}`}>
        <div>
          <ResendButton template={template} apiBaseUrl={apiBaseUrl} sender={localStorage?.getItem(`chat-session-Id-${chatId}`)} />
        </div>
      </div>}

      {/* Render options for the last message if available */}
      {messages?.[messages.length - 1]?.field === "dailog_select" &&
        messages?.[messages.length - 1]?.value === "" && (
          <Stack
            direction="row "
            sx={{ flexWrap: "wrap" }}
            spacing={2}
            className={styles.sender_right_side}>
            {messages?.[messages.length - 1].options?.map((data, index) => {
              return (
                <span style={{ margin: "3px 2px" }} className={styles.sender_chip}>
                  <Chip
                    label={data}
                    variant={messages?.[messages.length - 1].value === data ? "" : "outlined"}
                    onClick={(e) => {
                      if (chatClose) {
                        handleRating(data, setMsg, onClose, isFormAlreadySubmitted, chatId);
                      } else {
                        if (!createChat) {
                          handleSendDailogApi(data, chatId);
                        } else {
                          onChatMsgSubmit(e, data, chatId, isFormAlreadySubmitted, setMsg);
                        }
                      }
                    }}
                  />
                </span>
              );
            })}
          </Stack>
        )}

      {/* Render calendar for specific chat types */}
      {(createType === "arrange-callback" || createType === "property-viewing" || createType === "schedule-valuation") && (
        <div className={styles.sender_right_side} style={{ display: "flex" }}>
          <BotCal
            handleSendDailogApi={handleSendDailogApi}
            chatId={chatId}
            chatBotAdminData={chatBotAdminData}
            stopSendloading={stopSendloading}
            messages={messages}
            template={template}
            apiBaseUrl={apiBaseUrl}
          />
        </div>
      )}

      {range && <RangeSelector maxRange={maxRange} rangeData={rangeData} setRangeData={setRangeData} template={template} />}

      {/* Display typing indicator */}
      {chatLoading && (
        <div className={styles.chat_bubble}>
          <div className={styles.typing}>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotMessages;
