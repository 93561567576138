import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { FaAngleDown, FaEllipsisV } from "react-icons/fa";
import ChatbotMessages from "./ChatbotMessages";
import styles from "./chatbot.module.css";
import ChatbotMessageSend from "./ChatbotMessageSend";
import Loader from "../../customComponents/Loader";
import useChatMessages from "../hooks/useChatMessages";
import { chatImg, liveIcon, logo } from "../../../constants/images";

export default function Chatbot({ chatId2, onClose, connectToSocket, socketNewMessage, isChatClose, userImage, apiBaseUrl, chatBotAdminData }) {

  // Destructuring custom hook functions and state variables
  const { currentChatId, hasChatCreated, createType, createChat, messages, setMessages, loading, chatLoading,
    setchatLoading, isBotWorking, setIsBotWorking, chatClose, setChatClose, handleCloseChat, ValidateKey, handleRating, handleEmojiSelect,
    onChatMsgSubmit, handleSendDailogApi, isAddress, stopSendloading, range, maxRange } = useChatMessages(localStorage?.getItem(`chat-session-Id-${chatId2}`) || uuidv4(), apiBaseUrl, localStorage?.getItem(`chat-session-Id-${chatId2}`) ? true : false, chatBotAdminData);

  const [rangeData, setRangeData] = useState(0);
  const [captureFieldError, setCaptureFieldError] = useState("");
  const [captureFieldValue, setCaptureFieldValue] = useState("");
  // State for handling Popover component
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => { setAnchorEl(event.currentTarget); };
  const handleClose = () => { setAnchorEl(null); };



  // Determine whether Popover is open or not
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Effect to validate chat ID when it changes
  useEffect(() => {
    if (chatId2) { ValidateKey(chatId2); }
  }, [chatId2]);

  // Effect to connect to socket when chat is created
  useEffect(() => {
    if (currentChatId) {
      console.log("Connecting on API Response", currentChatId);
      connectToSocket(currentChatId);
    }
    else if (chatId2) {
      console.log("Connect the socket on page load");
      const chatDataStr = localStorage.getItem(chatId2);

      if (chatDataStr) {
        const chatBotConnectid = JSON.parse(chatDataStr)._id;
        connectToSocket(chatBotConnectid);
      }
    }
    else {
      console.log("Error count not connect the socket.");
    }
  }, [hasChatCreated, currentChatId]);

  // Effect to handle new messages received from socket
  useEffect(() => {
    if (socketNewMessage) {
      if (isChatClose || socketNewMessage?.userMessage?.close) { setChatClose((prev) => isChatClose); }

      setMessages((prevMessages) => [...prevMessages, socketNewMessage?.userMessage || socketNewMessage?.userMessageNew,]);

      if (socketNewMessage?.userMessage?.close) {
        let userMessage = {
          message: "Please provide rating for this conversation.",
          type: "rating",
          options: ["Good", "Average", "Bad"],
          sender: "bot",
          field: "dailog_select",
          value: ""
        }
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setChatClose((prev) => true);
      }
    }
  }, [socketNewMessage]);


  return (
    <div className={styles.all_chatpart_design}>
      {/* Header Section */}
      <div className={styles.all_chatpart_header}>
        {loading ? <div className={styles.with_avtar}>
          <img src={chatBotAdminData?.chatBotData?.chatbotImage || chatImg} alt="" />
        </div> : <div className={styles.with_avtar}>
          <img src={chatBotAdminData?.chatBotData?.chatbotImage || chatImg} alt="" />
          {chatBotAdminData?.chatBotData?.chatbotName || "Hii there !"}
        </div>}
        {/* More Options */}
        <div className={styles.more_Option}>
          <div onClick={handleClick} className={styles.donw_arrow}>
            <FaEllipsisV />
          </div>
          {/* Popover for additional options */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left", }}
            transformOrigin={{ vertical: "top", horizontal: "center", }}
            style={{ zIndex: 999999 }}>
            <Typography sx={{ px: 2, py: 1, cursor: "pointer" }} onClick={() => handleCloseChat(chatId2, onClose, handleClose)}>
              Close Chat
            </Typography>
          </Popover>
          {/* Close button */}
          <div onClick={onClose} className={styles.donw_arrow}>
            <FaAngleDown />
          </div>
        </div>
      </div>
      {/* Online Status */}
      <div className={styles.we_online_box}>
        <img src={liveIcon} alt="" /> We are online
      </div>
      {/* Chat Messages Section */}
      {loading ? (
        <div className={styles.all_chatpart_body}>
          <Loader size="medium"  template={""} />
        </div>
      ) : (
        <ChatbotMessages
          chatId={chatId2}
          setIsBotWorking={setIsBotWorking}
          messages={messages}
          chatLoading={chatLoading}
          setchatLoading={setchatLoading}
          setMessages={setMessages}
          setChatClose={setChatClose}
          handleSendDailogApi={handleSendDailogApi}
          createChat={createChat}
          onChatMsgSubmit={onChatMsgSubmit}
          createType={createType}
          chatBotAdminData={chatBotAdminData}
          stopSendloading={stopSendloading}
          onClose={onClose}
          chatClose={chatClose}
          handleRating={handleRating}
          apiBaseUrl={apiBaseUrl}
          range={range}
          maxRange={maxRange}
          setRangeData={setRangeData}
          rangeData={rangeData}
          setCaptureFieldError={setCaptureFieldError}
          setCaptureFieldValue={setCaptureFieldValue}

        />
      )}
      {/* Message Sending Section */}
      <ChatbotMessageSend
        onClose={onClose}
        messages={messages}
        isBotWorking={isBotWorking}
        chatClose={chatClose}
        chatId={chatId2}
        handleRating={handleRating}
        handleEmojiSelect={handleEmojiSelect}
        onChatMsgSubmit={onChatMsgSubmit}
        handleSendDailogApi={handleSendDailogApi}
        createChat={createChat}
        isAddress={isAddress}
        stopSendloading={stopSendloading}
        apiBaseUrl={apiBaseUrl}
        createType={createType}
        range={range}
        maxRange={maxRange}
        setRangeData={setRangeData}
        rangeData={rangeData}
        captureFieldError={captureFieldError}
        captureFieldValue={captureFieldValue}
        setCaptureFieldValue={setCaptureFieldValue}
      />
      {/* Powered By */}
      {!chatBotAdminData?.agencyConfig?.blockPoweredBy && <div className={styles.powered_box}>
        Powered By <a href="https://www.propertyjinni.co.uk/" style={{
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
          color: "inherit",
          marginLeft: "5px",
        }} target="_blank"> <img src={logo} alt="Propertyjinni logo" /> <span>Propertyjinni</span></a>
      </div>}
    </div>
  );
}
