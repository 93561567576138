import { useState } from "react";
import moment from "moment";
import Axios from "../../../utils/axios";
import { delayedExecution, isValidRating } from "../../../utils/validators";
import useBotData from "../../hooks/useBotData";

const useChatMessages = (sessionId, apiBaseUrl, isOldChat, chatBotAdminData) => {
  const { removeBotDataFromStorage, getChatbotData } = useBotData(apiBaseUrl);
  // State variables
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setchatLoading] = useState(false);
  const [hasChatCreated, setChatCreated] = useState(false);
  const [currentChatId, setCurrentChatId] = useState("");
  const [isBotWorking, setIsBotWorking] = useState(true);
  const [chatClose, setChatClose] = useState(false);
  const [ratingData, setRatingData] = useState(false);
  const [createChat, setCreateChat] = useState(false);
  const [createType, setcreateType] = useState("");
  const [stopSendloading, setStopSendloading] = useState(false);
  const [isAddress, setIsAddress] = useState(false);

  const [range, setRange] = useState(false);
  const [maxRange, setMaxRange] = useState(false);

  // Handle closing the chat
  const handleCloseChat = (chatId, onClose, handleClose) => {
    let conversationData = localStorage.getItem(chatId);
    localStorage.removeItem(`chat-session-Id-${chatId}`);
    if (conversationData && !chatClose) {
      Axios.post(`${apiBaseUrl}/botChat/closeChat`, {
        chatConversationId: JSON.parse(conversationData)._id, owner: false,
      }).then((res) => {
        setChatClose(true);
        let message = {
          message: "Please provide rating for this conversation.",
          type: "rating",
          options: ["Good", "Average", "Bad"],
          sender: "bot",
          field: "dailog_select",
          value: "",
        };
        setMessages((prev) => [...prev, message]);
        handleClose();
      });
    } else {
      Axios.post(`${apiBaseUrl}/domainKey/deleteTempraryChat`, { sender: sessionId })
        .then((res) => { console.log('Deleted successfully.'); })
        .catch((err) => { console.log(err); })

      removeBotDataFromStorage();

      onClose();
    }
  };

  // Create chat messages
  const createChatMessages = async (chatId) => {
    await getChatbotData(chatId); // Calling the validateKey in case the new chat is created after user has already interacted with the chatbot

    try {
      setchatLoading(true);
      const res = await Axios.post(`${apiBaseUrl}/botChat/createChatbotFlow`, { encryptedKey: chatId, sender: sessionId });
      localStorage.setItem(`chat-session-Id-${chatId}`, sessionId);

      for (let index = 0; index < res?.data?.data?.length; index++) {
        const data = res?.data?.data[index];
        await delayedExecution();

        if (data.nodeType === "greeting" || data.nodeType === "capture-user-details" || data.nodeType === "message" || data.nodeType === "property-brochure" || data.nodeType === "property-image" || data.nodeType === "property-details") {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "bot", type: data.nodeType, message: data.nodeDesc, customFieldTitle: data?.customFieldTitle, emailVerification: data?.emailVerification },
          ]);
        } else if (data.nodeType === "capture-location") {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "bot", type: data.nodeType, message: data.nodeDesc },
          ]);
          setIsAddress(true);
        } else if (data.nodeType === "question" || data.nodeType === "capture-visitor" || data.nodeType === "capture-property") {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: "bot",
              type: data.nodeType,
              message: data.nodeDesc,
              field: "dailog_select",
              options: (data.options || []).map((data1) => `${data1?.nodeDesc}`),
              value: "",
            },
          ]);
        }
      }
    } catch (error) {
      console.error("Error creating chat messages:", error);
    } finally {
      setchatLoading(false);
    }
  };

  // Validate chat key
  const ValidateKey = async (chatId) => {
    setLoading(true);

    try {
      if (localStorage.getItem(chatId)) {
        let conversationData = localStorage.getItem(chatId);
        const response = await Axios.get(`${apiBaseUrl}/botChat/getChatBotMessage?conversationId=${JSON.parse(conversationData)._id}`);
        
        if (response?.data?.data?.isActive) {
          setMessages(response?.data?.data?.chatMessages);
          setCreateChat(true);
          setCurrentChatId((prev) => response?.data?.data?._id);
        } else {
          localStorage.removeItem(chatId);
          const res = await Axios.post(`${apiBaseUrl}/domainKey/getTemporaryChat`, { encryptedKey: chatId, sender: sessionId });
          
          if (res.data?.data?.questions?.length > 0) {
            let modifiedQuestions = res.data?.data?.questions.map(question => {
              return question.sender === chatBotAdminData?.userId ? question : { ...question, sender: null };
            });

            for (let i = 0; i < modifiedQuestions.length; i++) {
              if (modifiedQuestions[i].type === "question" || modifiedQuestions[i]?.type === "capture-visitor" || modifiedQuestions[i]?.type === "capture-property") {
                modifiedQuestions[i] = {
                  ...modifiedQuestions[i], options: modifiedQuestions[i].options?.map((data1) => {
                    return `${data1?.nodeDesc}`;
                  }), value: modifiedQuestions[i + 1]?.message || "", field: "dailog_select"
                };
                setMessages((prev) => [...prev, modifiedQuestions[i]]);
              } else if (modifiedQuestions[i - 1]?.type === "question" || modifiedQuestions[i - 1]?.type === "capture-visitor" || modifiedQuestions[i - 1]?.type === "capture-property") {
                setMessages(prev => prev);
              } else {
                setMessages((prev) => [...prev, modifiedQuestions[i]]);
              }
            }

            if (res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.rangeValue) {
              setRange(res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.range);
              setMaxRange(res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.rangeValue);
            } else if (res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === 'capture-location') {
              setIsAddress(true);
              setMessages((prev) => [...prev.slice(0, prev.length - 1), { ...res.data?.data?.questions[res.data?.data?.questions?.length - 1], category: res.data?.data?.category }]);
            } else if (
              res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === "property-viewing" ||
              res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === "arrange-callback" ||
              res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === "schedule-valuation"
            ) {
              setcreateType(res.data?.data?.questions[res.data?.data?.questions?.length - 1].type);
              setMessages((prev) => [...prev.slice(0, prev.length - 1), { ...res.data?.data?.questions[res.data?.data?.questions?.length - 1], category: res.data?.data?.category }]);
            } else {
              setcreateType(res.data?.data?.questions[res.data?.data?.questions?.length - 1].type);
            }
          } else {
            await createChatMessages(chatId);
          }
        }
      } else {
        if (isOldChat) {
          const res = await Axios.post(`${apiBaseUrl}/domainKey/getTemporaryChat`, { encryptedKey: chatId, sender: sessionId });
          
          if (res.data?.data?.questions?.length > 0) {
            let modifiedQuestions = res.data?.data?.questions.map(question => {
              return question.sender === chatBotAdminData?.userId ? question : { ...question, sender: null };
            });

            for (let i = 0; i < modifiedQuestions.length; i++) {
              if (modifiedQuestions[i].type === "question" || modifiedQuestions[i]?.type === "capture-visitor" || modifiedQuestions[i]?.type === "capture-property") {
                modifiedQuestions[i] = {
                  ...modifiedQuestions[i], options: modifiedQuestions[i].options?.map((data1) => { return `${data1?.nodeDesc}`; }),
                  value: modifiedQuestions[i + 1]?.message || "", field: "dailog_select"
                };
                setMessages((prev) => [...prev, modifiedQuestions[i]]);
              } else if (modifiedQuestions[i - 1]?.type === "question" || modifiedQuestions[i - 1]?.type === "capture-visitor" || modifiedQuestions[i - 1]?.type === "capture-property") {
                setMessages(prev => prev);
              } else {
                setMessages((prev) => [...prev, modifiedQuestions[i]]);
              }
            }

            if (res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.rangeValue) {
              setRange(res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.range);
              setMaxRange(res.data?.data?.questions[res.data?.data?.questions?.length - 1]?.rangeValue);
            } else if (res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === 'capture-location') {
              setIsAddress(true);
              setMessages((prev) => [...prev.slice(0, prev.length - 1), { ...res.data?.data?.questions[res.data?.data?.questions?.length - 1], category: res.data?.data?.category }]);
            } else if (
              res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === "property-viewing" ||
              res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === "arrange-callback" ||
              res.data?.data?.questions[res.data?.data?.questions?.length - 1].type === "schedule-valuation"
            ) {
              setcreateType(res.data?.data?.questions[res.data?.data?.questions?.length - 1].type);
              setMessages((prev) => [...prev.slice(0, prev.length - 1), { ...res.data?.data?.questions[res.data?.data?.questions?.length - 1], category: res.data?.data?.category }]);
            } else {
              setcreateType(res.data?.data?.questions[res.data?.data?.questions?.length - 1].type);
            }
          } else {
            await createChatMessages(chatId);
          }
        } else {
          await createChatMessages(chatId);
        }
      }
    } catch (error) {
      setchatLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Save chat rating
  const handleSaveRating = (isFormAlreadySubmitted, rating) => {
    const ratingValue = rating === "good" ? 3 : rating === "average" ? 2 : 1;
    setchatLoading(true);
    const data = { rating: ratingValue, against: isFormAlreadySubmitted._id, user: isFormAlreadySubmitted.user_id, reviewFor: "chat bot", };

    Axios.post(`${apiBaseUrl}/review/addReview`, data).then((res) => {
      setRatingData(res.data.data);
      setchatLoading(false);
      setMessages((prev) => [
        ...prev.slice(0, prev.length),
        { sender: "bot", type: "review", message: "Please provide your review about our services.", },
      ]);
    }).catch((err) => {
      setchatLoading(false);
    });
  };

  // updating review 
  const handlepUpdateReview = (review, onClose, chatId) => {
    if (!review.trim()) { return; }

    localStorage.removeItem(`chat-session-Id-${chatId}`);
    let data = { reviewId: ratingData._id, comment: review, };
    setchatLoading(true);

    Axios.post(`${apiBaseUrl}/review/updateReview`, data).then((res) => {
      setIsBotWorking(false);
      setchatLoading(false);
      onClose();
      localStorage.removeItem(chatId);
    }).catch((err) => {
      setchatLoading(false);
    });;
  };

  // updating rating
  const handleRating = (msg, setMsg, onClose, isFormAlreadySubmitted, chatId) => {
    if (!msg.trim()) { return; }

    if (messages[messages.length - 1].type === "rating") {

      if (isValidRating(msg)) {
        setMessages((prev) => [...prev.slice(0, prev.length), { sender: "you", type: "message", message: msg, },]);
        handleSaveRating(isFormAlreadySubmitted, msg.toLowerCase(), msg);
      } else {
        setMessages([
          ...messages.slice(0, messages.length),
          { sender: "you", type: "message", message: msg, },
          {
            sender: "bot",
            type: "rating",
            options: ["Good", "Average", "Bad"],
            message: "You can only provide rating.",
            field: "dailog_select",
            value: "",
          },
        ]);
      }
      setMsg("");
    } else {
      handlepUpdateReview(msg, onClose, chatId);
      setMessages([
        ...messages.slice(0, messages.length),
        { sender: "you", type: "message", message: msg, },
        { sender: "bot", type: "review", message: "Thankyou for sharing your review.", },
      ]);
      setMsg("");
    }
  };

  // updating chat messages
  const updateChat = (isFormAlreadySubmitted, msg) => {
    setMessages([...messages, { sender: isFormAlreadySubmitted?._id, message: msg },]);
  };

  // send chat messages
  const onChatMsgSubmit = (e, msg, chatId, isFormAlreadySubmitted, setMsg) => {

    if (!msg.trim()) { return; }
    e.preventDefault();
    e.stopPropagation();
    setMsg("");
    setchatLoading(true);
    const data = { encryptedKey: chatId, chatId: isFormAlreadySubmitted._id, message: msg, };

    Axios.post(`${apiBaseUrl}/botChat/sendChatBotMessage`, data)
      .then((res) => {
        // Assuming the response contains the bot's message
        const botMessage = res.data.data.message;
        updateChat(isFormAlreadySubmitted, botMessage);
        setchatLoading(false);
      })
      .catch((err) => {
        setchatLoading(false);
      });
  };

  // sumbit chat flow questions
  const handleSendDailogApi = async (msg, chatId, time = false, fullAddress, setFullAddress) => {
    if (!msg.trim()) { return; }

    setStopSendloading(true);
    setchatLoading(true);
    setIsAddress(false);
    setRange(false);
    setMaxRange(0);
    setcreateType("");
    setMessages((prevMessages) => [
      ...prevMessages.slice(0, prevMessages.length - ((prevMessages[prevMessages.length - 1].type === "question" || prevMessages[prevMessages.length - 1].type === "capture-visitor" || prevMessages[prevMessages.length - 1].type === "capture-property") ? 1 : 0)),
      (prevMessages[prevMessages.length - 1].type === "question" || prevMessages[prevMessages.length - 1].type === "capture-visitor" || prevMessages[prevMessages.length - 1].type === "capture-property")
        ? { ...prevMessages[prevMessages.length - 1], value: msg, }
        : { sender: "you", type: "message", field: "message", message: time ? moment(msg).format("MMMM Do [at] h:mm A") : msg, },
    ]);

    console.log('handleSendDailogApi');



    Axios.post(`${apiBaseUrl}/botChat/createChatbotFlow`, { encryptedKey: chatId, sender: sessionId, message: msg, fullAddress: JSON.stringify(fullAddress), url: window.location.href })
      .then(async (res) => {
        setStopSendloading(false);
        setchatLoading(true);

        for (let index = 0; index < res?.data?.data?.length; index++) {
          const data = res?.data?.data[index];
          await delayedExecution();

          if (data.nodeType === "greeting" || data.nodeType === "capture-user-details" || data.nodeType === "message" || data.nodeType === "property-brochure" || data.nodeType === "property-image" || data.nodeType === "property-details") {

            if (data?.rangeValue) {
              setRange(data?.range);
              setMaxRange(data?.rangeValue);
            }
            if (data?.propertyList?.status) {
              setcreateType("property-valuation");
            }

            setMessages((prevMessages) => [...prevMessages, { ...data, sender: "bot", type: data.nodeType, message: data.nodeDesc, customFieldTitle: data?.customFieldTitle, emailVerification: data?.emailVerification, propertyDetails: data?.propertyDetails },]);
          } else if (data.nodeType === "capture-location") {
            setMessages((prevMessages) => [
              ...prevMessages, { sender: "bot", type: data.nodeType, message: data.nodeDesc, category: data.category, },
            ]);
            setIsAddress(true);
          } else if (data.nodeType === "question" || data.nodeType === "capture-visitor" || data.nodeType === "capture-property") {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                sender: "bot",
                type: data.nodeType,
                message: data.nodeDesc,
                field: "dailog_select",
                options: data.options?.map((data1) => { return `${data1?.nodeDesc}`; }),
                value: "",
              },
            ]);
          } else if (data.nodeType === "live-chat") {
            setMessages((prevMessages) => [...prevMessages, { sender: "bot", type: data.nodeType, message: data.nodeDesc, },]);
          } else {
            setMessages((prevMessages) => [
              ...prevMessages,
              { ...data, sender: "bot", type: data.nodeType, message: data.nodeDesc, category: data.category, },
            ]);
            setcreateType(data.nodeType);
          }
        }
        setchatLoading(false);

        if (res.data?.chatData?._id) {

          if (res.data?.chatData && res.data?.chatData?._id) {
            setCurrentChatId((prev) => res.data?.chatData?._id);
          }
          setCreateChat(true);
          localStorage.setItem(chatId, JSON.stringify({ _id: res.data?.chatData._id, user_id: res.data?.chatData.chatUserMetaData, }));
          setchatLoading(false);
          setChatCreated((prev) => true);

          Axios.get(`${apiBaseUrl}/botChat/getChatBotMessage?conversationId=${res.data?.chatData?._id}`)
            .then((response) => {
              setMessages(response?.data?.data?.chatMessages);
              setchatLoading(false);
            });
        }

        if (res.data.complete) {
          setcreateType("");
          setStopSendloading(false);
          localStorage.removeItem(`chat-session-Id-${chatId}`);

          if (!res.data?.chatData?._id) {
            setIsBotWorking(false);
            setChatClose(true);
          } else {
            setIsBotWorking(true);
            setChatClose(false);
          }
        }

      }).catch((err) => {
        setchatLoading(false);
      }).finally(() => {
        if (fullAddress) {
          setFullAddress();
        }
      });
  };

  // add emoji in messages
  const handleEmojiSelect = (emoji, textareaRef, setMsg, handleClose) => {
    const cursorPos = textareaRef.current.selectionStart;

    setMsg((prevMsg) => {
      const start = prevMsg.substring(0, cursorPos);
      const end = prevMsg.substring(cursorPos);
      return `${start}${emoji.native}${end}`;
    });
    handleClose();
  };

  // Return necessary functions and states from the custom hook
  return {
    stopSendloading, createType, messages, loading, chatLoading, isBotWorking, ratingData, chatClose,
    createChat, hasChatCreated, currentChatId, chatBotAdminData, isAddress,
    setMessages, setLoading, setchatLoading, setIsBotWorking, setRatingData, setChatClose, handleCloseChat, ValidateKey, handleRating, handlepUpdateReview,
    handleSaveRating, handleEmojiSelect, onChatMsgSubmit, handleSendDailogApi, range, maxRange
  };
};
export default useChatMessages;
